<template>
  <div>
    <v-row v-if="!isLoading">
      <v-col
        md="6"
        sm="8"
        cols="12"
      >
        <project-details></project-details>
      </v-col>
      <v-col
        md="6"
        sm="8"
        cols="12"
      >
        <project-edit-cover @updated="handleCoverUpdated"></project-edit-cover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import ProjectDetails from '@/components/project/ProjectDetails.vue'
import ProjectEditCover from '@/components/project/ProjectEditCover.vue'

export default {

  components: {
    ProjectDetails,
    ProjectEditCover,
  },

  setup(props, context) {
    const route = context.root.$route
    const id = ref(route.params.id)
    const store = context.root.$store
    const isLoading = computed(() => store.state.project.isLoading)

    store.dispatch('project/load', id).then(() => {
      console.log('Project loaded in project.vue')
    })

    const handleCoverUpdated = function handleCoverUpdated() {
      store.dispatch('project/refresh').then(() => {
        console.log('Project refreshed in project.vue')
      })
    }

    return {
      isLoading,
      handleCoverUpdated,
    }
  },
}
</script>
