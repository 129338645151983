<template>
  <div>
    <v-card class="overflow-hidden">
      <v-card-title>{{ project.title }}</v-card-title>
      <v-card-text>{{ project.description }}</v-card-text>
      <v-card-text>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <div class="me-auto pe-4">
          <p class="d-flex align-center mb-6">
            <v-icon color="primary">
              {{ icons.mdiLockOpenOutline }}
            </v-icon>
            <span class="ms-3">{{ visibility }}</span>
          </p>
          <p class="d-flex align-center mb-0">
            <v-icon color="primary">
              {{ icons.mdiAccountOutline }}
            </v-icon>
            <span class="ms-3">15 Members</span>
          </p>
        </div>

        <v-divider
          v-if="$vuetify.breakpoint.smAndUp"
          vertical
          inset
        >
        </v-divider>

        <div class="ms-auto ps-4">
          <p class="d-flex align-left mb-6">
            <v-icon color="primary">
              {{ icons.mdiStarOutline }}
            </v-icon>
            <span class="ms-3">{{ stars }}</span>
          </p>
          <p class="d-flex align-left mb-0">
            <v-icon color="primary">
              {{ icons.mdiCheckOutline }}
            </v-icon>
            <span class="ms-3">{{ tasks }} Tasks Remaining</span>
          </p>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import { ref, computed } from '@vue/composition-api'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiCheckOutline,
} from '@mdi/js'

export default {
  setup(props, context) {
    const store = context.root.$store
    const tasks = ref(6)
    const stars = ref(27) // Will come from project
    const visibility = ref('Everyone') // Will come from project

    const project = computed(() => store.state.project.current)

    return {
      tasks,
      project,
      visibility,
      stars,
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiCheckOutline,
      },
    }
  },
}
</script>
