<template>
  <div>
    <v-card>
      <v-img
        :src="coverImage"
        height="250"
      ></v-img>
      <v-card-actions class="dense">
        <v-btn
          color="primary"
          text
          @click="$refs.imageFile.click()"
        >
          Upload New Cover
        </v-btn>

        <input
          ref="imageFile"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
          @change="coverChoiceUpdated()"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import media from '@/library/media'

export default {
  setup(props, context) {
    const store = context.root.$store
    const coverImage = ref('')
    const project = computed(() => store.state.project.current)

    const loadCover = async () => {
      if (project.value.cover != null) {
        coverImage.value = await media.getImageObjectUrl(project.value.cover)
      }
    }

    loadCover()

    const coverUpdated = function coverUpdated() {
      context.emit('updated', null)
    }

    const uploadImage = async function uploadImage(image) {
      const details = {
        name: 'Cover',
        description: 'Project cover image',
        notes: '',
      }
      const result = await media.uploadImage(image, details)
      coverImage.value = await media.getImageObjectUrl(result.id)
      store.dispatch('project/setcover', result.id).then(() => {
        coverUpdated()
      })
    }

    const coverChoiceUpdated = function coverChoiceUpdated() {
      const imageFile = context.refs.imageFile.files[0]
      uploadImage(imageFile)
    }

    return {
      coverImage,
      coverChoiceUpdated,
    }
  },
}
</script>
